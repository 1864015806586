<template>
    <div ref="container"/>
</template>
<script>
import {loadCdnStyle} from 'o365.modules.helpers.js';
    loadCdnStyle('leaflet/leaflet.css');
    loadCdnStyle('leaflet-draw/leaflet-draw.css');
    loadCdnStyle('leaflet-locate/leaflet-locate.css');
    loadCdnStyle('leaflet-markercluster/leaflet-markercluster.css');
</script>
<script setup>
    import L from "leaflet";
    import 'leaflet-plugins';
    import 'leaflet-locate/leaflet-locate.min.js';
    import 'leaflet-draw/leaflet-draw.min.js';
    import 'leaflet-markercluster/leaflet-markercluster.min.js';
    import { useDataObjectEventListener } from 'o365.vue.composables.EventListener.ts';
    import {onMounted,onUnmounted,ref, onUpdated, watch} from 'vue';

 //   import {loadCdnStyle} from 'o365.modules.helpers.js';
    import LeafletHelper from 'system.modules.LeafletHelper.ts';
    const container = ref(null);
    const markers = new Map();
    let markerLayer = null;
    let map = null;
    let drawBtn = null;
    let clearBtn = null;

  //  loadCdnStyle('leaflet/leaflet.css');
  //  loadCdnStyle('leaflet-draw/leaflet-draw.css');
   // loadCdnStyle('leaflet-locate/leaflet-locate.css');

    const props = defineProps({
        /**Data Object Item */
        item:{
            type:Object
        },
        /**Data used to display multiple points */
        dataObject:{
            type:Object
        },
        config:{
            type:Object
        },
        clusterGroups:{
            type:Boolean,
            default:false
        },
        update:{
            type:Boolean,
            default:false
        },
        remove:{
            type:Boolean,
            default:false
        }
    });

    const helper = new LeafletHelper(props.config,props.dataObject);

    onMounted(()=>{
        init();
      
    })
watch(() => props.update, v => {
    if (v) {
        props.item.save()
    } 
}, { immediate: true });

watch(() => props.remove, v => {
    if (v) {
        props.item.GeoLocation = null;
        props.item.save();
    } 
}, { immediate: true });

    async function init(){
        if(props.dataObject){
            useDataObjectEventListener(props.dataObject,'DataLoaded', () => {
                clearMap();
                helper.getMarkers().then(data=>{
                    data.forEach(item=>{
                        setMarker(item);   
                    })
                    window.setTimeout(()=>{
                         map.fitBounds(markerLayer.getBounds());
                    },100)
                   
                })
            })
        }
        const centerLocation = await helper.getCenterLocation(props.item);
        const defaultLocation = centerLocation == null ? { lat: 59.59780273035881, lng: 5.745103582012234 } : { lat: Number(centerLocation.coordinates[1]), lng: Number(centerLocation.coordinates[0]) };
        const config = helper.getMapConfig();
        if(centerLocation) config.center = centerLocation;
        map = L.map(container.value, {
            center: defaultLocation,
            zoomControl: false,
            zoom: 50,
        });

        L.tileLayer(helper.getTilesUrl()).addTo(map);
        initPlugins();
        if( defaultLocation ){
            var latlng = new L.latLng(defaultLocation.lat, defaultLocation.lng);   
            markerLayer.addLayer(new L.marker(latlng));
        }
    }

    async function initPlugins(){

        if(!props.dataObject){

        
            clearBtn = L.control.custom({
                title: $t('Clear location'),
                position: "topleft",
                content: `<div class="leaflet-bar">
                    <a href="#">
                    <i class="bi bi-x-lg"></i>
                </a></div>`,
                events:{
                    click: function(data)
                    {
                        clearMap();   
                        helper.updateItemLocation(props.item, null, null);
                        setMarkerLocation();

                    },
                }
            })
            clearBtn.addTo(map);
        }

        L.control.locate({
            position: "topright",
            strings: {
                title: $t("Find my location"),
            },
            locateOptions: {
                enableHighAccuracy: true,
            },
        }).addTo(map);

        markerLayer = (props.clusterGroups?L.markerClusterGroup():new L.FeatureGroup()).addTo(map);
        
        L.drawLocal.draw.toolbar.buttons.marker = $t( "Select point" );

        const drawOptions = {draw: {
                polyline     : false ,
                polygon      : false ,
                circle       : false ,
                circlemarker : false ,
                rectangle    : false ,
                marker       : !props.dataObject  ,
            }
        };
        if(props.dataObject){
            drawOptions["edit"] = {
                featureGroup: markerLayer, //REQUIRED!!
                remove: props.dataObject.allowDelete,
               // edit: props.dataObject.allowUpdate
            }
            
        }
       
        drawBtn = new L.Control.Draw(drawOptions);
        drawBtn.addTo( map );

        map.on("draw:created", ({ layer }) => {
            const { geometry } = layer.toGeoJSON();
            if( props.item ) {
                props.item.GeoLocation = JSON.stringify(geometry);
            }
            markerLayer.addLayer(L.marker(layer.getLatLng()));

            // helper.updateItemLocation(props.item, lat, lng);
            // setMarkerLocation();
        });

        map.on("draw:drawstart", () => {
            clearMap();
        });
        map.on("draw:edited", (e) => {

            var layers = e.layers;
            layers.eachLayer(function (layer) {
                const { geometry } = layer.toGeoJSON();
                helper.updateLocation(markers.get(layer),geometry.coordinates)

            });
        });
        map.on("draw:deleted", (e) => {
            var layers = e.layers;
            //const vRemoveKeys = [];
            layers.eachLayer(function (layer) {
              // vRemoveKeys.push(markers.get(layer));
               helper.updateLocation(markers.get(layer),[null,null])
               markers.delete(layer);
            });
           
           // helper.bulkRemove(vRemoveKeys);
        });

        setBtnstates();
        // setMarkerLocation();

    }

    function setMarker(markerLocation, setView){
        // on daily-log markerLocation is object without .lat .lng
        // if(markerLocation && markerLayer  && markerLocation.lng){()
        if(markerLocation && markerLayer){
            const layer =  L.marker(markerLocation)
            markerLayer.addLayer(layer );
            markers.set(layer,markerLocation.id);
           
            if(markerLocation.message){
                layer.bindPopup(markerLocation.message);
            }

            if(setView) map.setView(markerLocation);
        }
    }

    async function setMarkerLocation(){
        if(props.dataObject){
          
        }else{
            const markerLocation = helper.getMarker(props.item);

            setMarker(markerLocation,true);
        }
       

         setBtnstates();
       
    }

    function setBtnstates(){
       
        const markerLocation = props.item?helper.getMarker(props.item):null;
        
        if(markerLocation && markerLocation.lng){
            if(clearBtn) clearBtn._container.style.display = '';
            drawBtn._container.style.display = 'none';
        }else{
            if(clearBtn) clearBtn._container.style.display = 'none';
            drawBtn._container.style.display = '';
        }

        if(props.dataObject && !props.dataObject.allowUpdate){
            const vEditBtn = drawBtn._container.querySelector(".leaflet-draw-edit-edit");
            if(vEditBtn) vEditBtn.style.display = "none";
        }
    }

    function clearMap(){
        markers.clear();
        if(markerLayer && props.item){
            markerLayer.clearLayers();
            markerLayer.closePopup();
        }
    }

    onUnmounted(()=>{
        console.log("unmounted");
    })
    onUpdated(()=>{
        clearMap();
        // setMarkerLocation();
    })
  

    function getControl(){
        return {map,markerLayer}
    }
    //display one
    defineExpose({getControl})
</script>